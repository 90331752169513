import React from "react"
import { StaticQuery, graphql } from "gatsby"
import WidgetRecentPosts from "./widget-recent-posts"

const NewsWidgetRecentPosts = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressNews(filter: {status: {eq: "publish"}}, sort: {order: DESC, fields: date}, limit: 3) {
            nodes {
              post_id
              title
              slug
              thumbnail {
                sizes {
                  thumbnail
                }
              }
            }
          }
        }
      `}
      render={data => {
        return <WidgetRecentPosts posts={data.allWordpressNews.nodes} />
      }}
    />
  )
}

export default NewsWidgetRecentPosts
