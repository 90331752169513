import React from "react"
import NewsSearch from "./news-search"
import NewsWidgetCategories from "./news-widget-categories"
import NewsWidgetTags from "./news-widget-tags"
import NewsWidgetRecentPosts from "./news-widget-recent-posts"

const NewsContent = ({ children }) => {
  return (
    <div className="blog-content-component is-padding-horizontal-4-touch is-margin-vertical-10">
      <div className="container">
        <div className="columns">
          <div className="column is-8-desktop is-8-tablet">
            <div className="is-margin-bottom-10-touch">
              {children}
            </div>
          </div>
          <div className="column is-1-desktop is-hidden-touch"></div>
          <div className="column is-3-desktop is-4-tablet">
            <NewsSearch />
            <NewsWidgetCategories />
            <NewsWidgetRecentPosts />
            <NewsWidgetTags />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewsContent
