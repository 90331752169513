import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageBanner from "../components/page-banner"
import NewsContent from "../components/news-content"
import NewsList from "../components/news-list"
import HomeContact from "../components/home-contact"
import Router from "../domain/router"

const Page = ({ data, pageContext }) => {
  const { author, term, mainPage } = data
  const posts = data.posts.nodes
  const parent_link = Router.blogPath("news")
  
  let title = term && term.name
  if (author) title = author.name

  return (
    <Layout>
      <SEO term={term} author={author} />
      <PageBanner title={title} parentPost={mainPage} parent_link={parent_link}/>
      <NewsContent>
        <NewsList posts={posts} pageContext={pageContext} />
      </NewsContent>
      <HomeContact />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query($termId: Int!, $skip: Int!, $limit: Int!) {
    posts: allWordpressNews(
      limit: $limit,
      skip: $skip,
      sort: {order: DESC, fields: date_gmt},
      filter: {news_category_ids: {in: [$termId]}, status: {eq: "publish"}}
    ) {
      nodes {
        ...NewsItem
      }
    }

    term: wordpressNewsCategory(term_id: {eq: $termId}) {
      term_id
      taxonomy
      slug
      name
      count
      yoast_title
      yoast_json_ld
      yoast_meta {
        content
        name
        property
      }
    }

    mainPage: wordpressMainPage(name: {eq: "news-superbom"}) {
      ...MainPageFull
    }
  }
`
