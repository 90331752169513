import React from "react"
import { StaticQuery, graphql } from "gatsby"
import WidgetTags from "./widget-tags"

const NewsWidgetTags = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressNewsTag(sort: {order: DESC, fields: count}, filter: {count: {ne: 0}}, limit: 15) {
            nodes {
              count
              slug
              name
              term_id
            }
          }
        }
      `}
      render={data => {
        return <WidgetTags
          type="news"
          itemClassname="is-primary"
          tags={data.allWordpressNewsTag.nodes} />
      }}
    />
  )
}

export default NewsWidgetTags
