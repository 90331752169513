import React from "react"
import { StaticQuery, graphql } from "gatsby"
import WidgetCategories from "./widget-categories"

const NewsWidgetCategories = ({ type }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressNewsCategory(sort: {order: ASC, fields: name}, filter: {count: {ne: 0}}) {
            nodes {
              id
              count
              slug
              name
              term_id
            }
          }
          allWordpressNews(filter: {status: {eq: "publish"}}) {
            group(field: news_category___id) {
              totalCount
              fieldValue
            }
          }
        }
      `}
      render={({allWordpressNewsCategory, allWordpressNews}) => {
        return <WidgetCategories
          linkClassName="has-text-carnation"
          type={type}
          categories={allWordpressNewsCategory.nodes} groupCounts={allWordpressNews.group} />
      }}
    />
  )
}

export default NewsWidgetCategories
